<template>
  <component :is="questionData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="questionData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching question data
      </h4>
      <div class="alert-body">
        No questions found with this question id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-questions-list'}"
        >
          Questions List
        </b-link>
        for other questions.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
        <b-overlay
            :show="uploadingImage"
            @recMesPri="({data}) => {this.uploadingImage = true}"
            no-wrap
        />
      <b-row>
        <b-col md="12">
          <!-- En Title -->
          <validation-provider
              #default="validationContext"
              name="English Title"
              rules="required"
          >
            <b-form-group
                label="English Title"
                label-for="title-en"
            >
              <ckeditor :editor="editor" :state="getValidationState(validationContext)" style="height: 500px" id="title-en" rows="20" v-model="questionData.title_en"></ckeditor>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Ar Title -->
          <b-form-group
              label="Arabic Title"
              label-for="title-ar"
          >
            <b-form-input
                id="title-ar"
                v-model="questionData.title_ar"
                autofocus
                trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- Question Image -->
          <b-form-group
              label="Question Image"
              label-for="question-image"
          >
            <file
                title="Question Image"
                @file_uploaded="({media})=>{questionData.image = media.url;questionData.image_id = media.id}"
                :key="rerender_component1"
                :default_place_holder_src="questionData.image"
            ></file>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="(answer,index) in questionData.answers" :key="index">
          <!-- Answers -->
          <b-col md="6">
            <validation-provider
                #default="validationContext"
                :name="'English Answer #'+(index+1)"
                rules="required"
            >
              <b-form-group
                  :label="'English Answer #'+(index+1)"
                  :label-for="'title-en'+index"
              >
                <b-form-input
                    :id="'title-en'+index"
                    v-model="answer.answer_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
                :label="'Arabic Answer #'+(index+1)"
                :label-for="'title-ar'+index"
            >
              <b-form-input
                  :id="'title-ar'+index"
                  v-model="answer.answer_ar"
                  autofocus
                  trim
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label="Is Correct Answer"
                label-for="landmark"
            >
              <b-form-radio
                  v-model="answer.is_correct"
                  class="custom-control-primary"
                  :name="'is_correct'+index"
                  value="1"
              >
                Yes
              </b-form-radio>
              <b-form-radio
                  v-model="answer.is_correct"
                  class="custom-control-primary"
                  :name="'is_correct'+index"
                  value="0"
              >
                No
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
      <b-row>
        <b-col md="12">
          <!-- English explanation -->
          <b-form-group
              label="English Explanation"
              label-for="english-explanation"
          >
            <ckeditor :editor="editor" :config="editorConfig" style="height: 200px" :id="'en'" rows="10" v-model="questionData.explanation_en"></ckeditor>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- Arabic explanation -->
          <b-form-group
              label="Arabic Explanation"
              label-for="arabic-explanation"
          >
            <ckeditor :editor="editor" :config="editorConfig" style="height: 200px" :id="'en'" rows="10" v-model="questionData.explanation_ar"></ckeditor>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row  style="margin-top: 70px">
        <b-col md="6">
          <b-form-group
              label="Choose Explanation Audio Type"
              label-for="landmark"
          >
            <b-form-radio
                v-model="questionData.voice_type"
                class="custom-control-primary"
                :name="'explanation_audio_'"
                value="1"
            >
              Upload Mp3 File
            </b-form-radio>
            <b-form-radio
                v-model="questionData.voice_type"
                class="custom-control-primary"
                :name="'explanation_audio_'"
                value="2"
            >
              Record Voice
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="questionData.voice_type == 1">
          <!-- Explanation Voice -->
          <b-form-group
              label="Select Audio File"
              label-for="mc-is-active"
          >
            <b-form-file
                v-model="questionData.explanation_voice"
                :id="'audio'"
                accept=".mp3,audio/*"
                @change="uploadExplanationAudio($event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" v-if="questionData.voice_type == 2">
          <b-form-group
              label="Record Audio"
              label-for="mc-is-active"
          >
            <audio-recorder
                upload-url="notes/upload-pdf"
                :attempts="1"
                :time="2"
                :before-recording="callback"
                :pause-recording="recordingPaused"
                :after-recording="afterRecording"
                :select-record="callback"
                :before-upload="callback"
                :successful-upload="successfulUpload"
                :failed-upload="failedUpload"/>
          </b-form-group>
          <b-overlay
              :show="recordShow"
              no-wrap
          />
        </b-col>
        <b-col md="6">
          <!-- Explanation Image -->
          <b-form-group
              label="Explanation Image"
              label-for="exp-image"
          >
            <file
                title="Select Explanation Image"
                @file_uploaded="({media})=>{questionData.explanation_image = media.url; questionData.explanation_image_id = media.id}"
                :key="rerender_component"
                :default_place_holder_src="questionData.explanation_image"
            ></file>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <!-- Course -->
          <validation-provider
              #default="validationContext"
              name="Choose Course"
              rules="required"
          >
            <b-form-group
                label="Choose Course"
                label-for="course"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="questionData.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="courseOptions"
                  @input="val => updateCourseID(val)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="categoriesOptions.length > 0"
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="questionData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  @input="val => updateCategoryId(val)"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
            <b-form-group
                label="Choose Sub Category"
                label-for="sub-category"
            >
              <v-select
                  v-model="questionData.sub_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="questionData.subs"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateSubCategoryId(val)"
                  input-id="category-id"
              />
            </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
              label="Is Free Content"
              label-for="landmark"
          >
            <b-form-radio
                v-model="questionData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="true"
            >
              Yes
            </b-form-radio>
            <b-form-radio
                v-model="questionData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="false"
            >
              No
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="questionData.is_active"
                :checked=questionData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted, watch} from '@vue/composition-api'
import {AudioRecorder} from 'vue-audio-recorder-no-eventbus'
import router from '@/router'
import axios from '@axios'
import store from '@/store'
import questionStoreModule from '../questionStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert, BButton, BCard, BCol, BForm, BFormInvalidFeedback,BFormCheckbox, BFormGroup,
  BFormFile, BFormInput, BLink, BRow,BFormTextarea,BFormRadio, BOverlay} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import formValidation from "@core/comp-functions/forms/form-validation";
import ClassicEditor from '../../../../ckeditor5-custom';
import * as UploadAdapter from '../../../../src/UploadAdapter';
import Vue from "vue";
export const eventBus = new Vue();
export default {
  components: {
    ClassicEditor,
    AudioRecorder,
    BCard,
    BOverlay,
    BFormRadio,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  // watch: {
  //   voice(old,neww){
  //     alert(old)
  //     this.voice = old
  //   }
  // },
  created() {
    axios.get(`/questions/${router.currentRoute.params.id}`).then(response => {
      this.voice = response.data.data.explanation_voice
    })
    UploadAdapter.eventBus.$on('startedUploading', (data) => {
      this.uploadingImage = true
    })
    UploadAdapter.eventBus.$on('endedUploading', (data) => {
      this.uploadingImage = false
    })
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
        })
    let course_id = null
    setTimeout(() => course_id = this.questionData.course_id, 2000);
    setTimeout(() => this.$http.get('/categories?course_id='+course_id)
        .then(response => {
          let that = this
          this.lists = response.data.data.map(function(item){
            that.categoriesOptions.push({
              label : item.label,
              value: item.value+""
            })
          })
        }), 2000);

  },
  methods:{
    emitMethod(){
      this.$emit("voiceChanged", {});
    },
    callback (data) {
      console.log(data)
      console.debug(data)
    },
    recordingPaused(data){
      console.log('finished' + data)
    },
    afterRecording(data){
      this.recordShow = true
      let form = new FormData();
      this.show = true
      form.append('name', 'my-pdf');
      form.append('file', data.blob);

      let config = {
        header: {
          'Content-Type': 'audio/mp3'
        }
      }
      this.$http.post('notes/upload-pdf',
          form,
          config
      ).then(response => {
        this.questionData.explanation_voice = response.data.path
        this.show = false
        this.recordShow = false
      })
      // console.log(data.blob)
    },
    successfulUpload(data){
      console.log('succ')
    },
    failedUpload(data){
      console.log('failed')
    },
    uploader(editor)
    {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        this.uploadingImage = true
        return new UploadAdapter.default( loader );
      };
    },
    updateSubCategoryId(val){
      this.questionData.sub_category_id = val
    },
    updateCategoryId(category_id){
      this.questionData.category_id = category_id
      this.questionData.subs.splice(0)
      this.$http.get('/sub-categories?'+'course_id='+this.questionData.course_id+'&category_id='+category_id)
          .then(response => {
            let that = this
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.questionData.subs.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    updateCourseID(course_id){
      this.questionData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lists.splice(0)
            this.questionData.subs.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.updateCategoryId(this.questionData.category_id)
    },
    setImage(imageId) {
      this.questionData.video_thumb = imageId
    },
    changeValue(){
      let val = this.questionData.video_type
      this.questionData.video_type = val
    },
    uploadExplanationAudio(event){
      let data = new FormData();
      data.append('name', 'my-audio');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.questionData.explanation_voice = response.data.path
      })
    },
  },
  mounted() {

  },
  data(){
    return{
      voice: null,
      uploadingImage: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent' ,'|','insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo','|','alignment', 'code', 'imageResize', 'codeBlock', 'findAndReplace', 'fontColor', 'fontBackgroundColor', 'fontSize','fontFamily', 'highlight', 'htmlEmbed', 'removeFormat', 'underline' ],
        table: {
          toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
          this.uploadingImage = true
          return new UploadAdapter.default(loader);
        }}],
        language: 'nl',
      },
      required,
      url,
      courseOptions: [],
      rerender_component: 0,
      rerender_component1: 0,
      show: false,
      lists: [],
      categoriesOptions: [],
      video_type: null,
    }
  },
  setup() {
    const blankQuestionData = {
      title_en: '',
      title_ar: '',
      description_en: '',
      description_ar: '',
      explanation_image_id: null,
      image_id: null,
      explanation_image: '',
      explanation_voice: null,
      explanation_en: '',
      explanation_ar: '',
      course_id: '',
      category_id: '',
      sub_category_id: '',
      subs: [],
      answers: [],
      is_free_content: null,
      image: null,
      file: null
    }
    const lectureImageId = ref(null);
    const toast = useToast()

    const questionData = ref(JSON.parse(JSON.stringify(blankQuestionData)))
    const resetQuestionData = () => {
      questionData.value = JSON.parse(JSON.stringify(blankQuestionData))
    }

    const EVENT_APP_STORE_MODULE_NAME = 'questions'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, questionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('questions/fetchQuestion', {id: router.currentRoute.params.id})
        .then(response => {
          questionData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            questionData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: questionData.value.title_en,
        title_ar: questionData.value.title_ar,
        is_active: questionData.value.is_active,
        is_free_content: questionData.value.is_free_content,
        url: questionData.value.path,
        course_id: questionData.value.course_id,
        category_id: questionData.value.category_id,
        sub_category_id: questionData.value.sub_category_id,

        explanation_en: questionData.value.explanation_en,
        explanation_ar: questionData.value.explanation_ar,
        explanation_voice: questionData.value.explanation_voice,
        explanation_image_id: questionData.value.explanation_image_id,
        image_id: questionData.value.image_id,
        answers: questionData.value.answers
      }
      store.dispatch('questions/updateQuestion', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-questions-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Question Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {
            // console.log(error.response.data.message)
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'AlertTriangleIcon',
                position: 'top-center',
                variant: 'danger',
              },
            }, {
              position: 'top-center'
            })
          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetQuestionData)
    return {
      onUpdate,
      questionData,
      lectureImageId,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>
.ar-icon{
svg{
  margin-top: -10px;
}
}
.ar-content{
  height: 276px;
}
.ck{
  height: 200px;
}
.ar-player-bar{
  width: 64%!important;
}
.ar-player-actions{
  width: 10%!important;
  display: block!important;
  margin-right: 10px!important;
  margin-top: 5px!important;
}
.ar{
  width: 100%!important;
}
.ar-recorder{
  margin-left: -6%;
}
.ar-recorder__duration{
  margin-top: 36px !important;
}
.ar-icon svg{
  vertical-align: baseline;
}
.ar-icon__xs svg{
  margin-top: 22px;
}
</style>
